
.container_whatsapp {
  position: fixed; 
  z-index: 9999999999999999999; 
  bottom: 0; 
  right: 2%;
  font-family: "Raleway", Arial, sans-serif;
  font-weight: 300;
  font-size: 15px;
  max-width: 360px;
  width: 100%;
  }
  

.container_chat_whatsapp {
font-size: 14px;
border-radius: 12px; 
width: 100%;
margin-bottom: 15px;
}

#container_chat_whatsapp {
  opacity: 0;
  display: none;
}

.header_chat_whatsapp {
background-color: rgb(9, 94, 84); 
color: white; 
padding: 14px; 
border-radius: 12px 12px 0px 0px;
text-align: center;
display: flex;
justify-content: flex-start;
align-items: center;
font-weight: 600;

  .header_chat_whatsapp_profil{
    background: url(/images/profil_whatsapp.jpg) no-repeat right top;
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-right: 14px;
    position: relative;
    &:after {
      content: "";
      bottom: 0px;
      right: 0px;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      background-color: rgb(74, 213, 4);
      display: block;
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      border: 2px solid rgb(9, 94, 84);
    }
  }
}

.message_chat_whatsapp {
  padding: 30px 30px 30px 20px;
  background-color: rgb(230, 221, 212);
  position: relative;
  overflow: auto;
  max-height: 382px;
  &:before {
    display: block;
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 0.08;
    background-image: url(https://elfsight.com/assets/chats/patterns/whatsapp.png);
  }
  .message_whatsapp {
    padding: 7px 14px 6px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px 8px 8px;
    position: relative;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
    margin-top: 4px;
    max-width: 150px;
    line-height: 1.3;
    .title_message_whatsapp {
      font-weight: 700;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 12px;
    }
    &::before {
      position: absolute;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      top: 0px;
      left: -12px;
      width: 12px;
      height: 19px;
    }
  }
}

.input_chat_whatsapp {
background-color: #f0f0f0;
border-radius: 0px 0px 12px 12px;
padding: 8px 0;
position: relative;
}

.input_chat_whatsapp input {
max-width: 260px;
width: 100%;
border-radius: 20px;
margin: 1px 1px 0px 10px;
padding:10px;
font-family: "Raleway", Arial, sans-serif;
font-weight: 300;
font-size: 13px;
background-color: #ffffff;
border: 1px solid #ffffff;
outline: #ffffff;
}

.button_whatsapp {
  height: 64px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
  border-radius: 50%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  outline: transparent;
  background-color: rgb(255, 255, 255);
  margin: 0px 0px 20px auto;
  width: 64px;
  &:after {
    content:"";
    width: 10px;
    height: 10px;
    background-color: rgb(255, 0, 0);
    display: block;
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    right: 4px;
    top: 4px;
  }
  svg {
    width: 32px;
    height: 32px;
  }
}

.send_chat_whatsapp {
  color: #9a9a9a;
  cursor:pointer;
}

button {border: none;}
button svg {
float: right;
position: absolute;
z-index: 999999999999;
right: 23px;
top: 50%;
transform: translateY(-50%);
bottom: 81px;
font-size: 18px !important;
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  outline: transparent;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  &::before, &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: rgb(255, 255, 255);
    display: block;
    border-radius: 2px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
