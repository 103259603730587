nav {
    background-color: $white;
    font-weight: 500;
    font-size: 14px;
    position: fixed;
    width: 100%;
    z-index: 2;
    @media (max-width: $mq-tablet) {
        font-size: 13px;
    }
    #menu_open_eventClick {
        background: url(/images/Icone_menu.svg) no-repeat left center;
        padding: 10px 50px 10px 30px;
    }
    #menu_close_eventClick {
        background: url(/images/icone_close-menu.svg) no-repeat left center;
        padding: 10px 50px 10px 30px;
    }

    @media (max-width: $mq-mobile) {
        #menu_open_eventClick ~ li{

            display: none;
        }
    }
        

    ul {
        margin: 0;
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        @media (max-width: $mq-mobile) {
            flex-wrap: wrap;
        }
        align-items: center;
        padding: 40px 60px 40px 20px;
        @media (max-width: $mq-tablet) {
            padding: 20px 0px 0px 0px;
        }
    
        a { 
            color: $black;
            font-size: 1rem;
        }

        .nav__el a {
            font-size: 1.7rem;
        }
        
        li{
            cursor: pointer;
            font-size: 1.05rem;
            text-decoration: none;
            list-style: none;
            margin-left: 50px;

            img {
                width: 24px;
            }  
        }
        li.order {
            background-color: $black;
            a{
                color: $white;
                font-size: 1.1rem;
                text-decoration: none;
                padding: 0 35px;
                line-height: 50px;
            }
        }
    }

    .navbar-menu {
        color: $white;
        position: fixed;
        display: none;
        top: 0;
        right: 0;
        width: 50%;
        overflow: hidden;
        height: 100%;
        z-index: -1;
        @media (max-width: $mq-tablet) {
            width: 70%;
        }
        @media (max-width: $mq-mobile) {
            width: 100%;
        }
        .order {
            background-color: $white;
            a {
                color: $black;  
            }
        }
        .navbar_menu-content {
            background-color: $black;
            height: 100%;
            // opacity: 0;
            ul li {
                margin-bottom: 20px;
            }
        }
        ul {
            justify-content: flex-start;
            margin-left: 50px;
            @media (max-width: $mq-mobile) {
                justify-content: flex-end;
                margin-right: 40px;
            }
        }
        li a {
            color: $white;
        }
        ul:nth-child(2){
            margin-top: 120px;
            margin-left: 100px;
            font-family: $secondaryFont;
            font-weight: 900;
            display: block;
            @media (max-width: $mq-tablet) {
                margin-left: 80px;
            }
            @media (max-width: $mq-mobile) {
                margin: 0px;
            }
            li {
                font-size: 26px;
                margin-bottom: 55px;
                list-style: decimal-leading-zero;
                padding-left: 50px;
                @media (max-width: $mq-mobile) {
                   list-style: none;
                   text-align: center;
                   margin: 25px 0;
                   padding: 0;
                }

            }
        }
        .social_icon {
            display: flex;
            justify-content: flex-end;
            padding: 0px 180px 80px;
            @media (max-width: $mq-mobile) {
                justify-content: space-around;
                padding: 20px 0 40px;
            }

            a {
                margin-left: 50px;
                @media (max-width: $mq-mobile) {
                    margin: 0 20px;
                }
            }
        }
    }
}