.service {
    margin: 150px 0;
    @media (max-width: $mq-tablet) {
        margin: 40px 0;
    }
    @media (max-width: $mq-mobile) {
        margin: 0 0 70px;
    }
}

.swiper-slide_title {
    position: absolute;
    padding-left: 10%;
    color: $white;
    text-align: left;
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    .swiper-slide-title_info{
        max-width: 500px;
        @media (max-width: $mq-mobile) {
            width: 90%;
        }
    } 
    h2 {
        font-size: 60px;
        margin-bottom: 40px;
        @media (max-width: $mq-mobile) {
            font-size: 42px;
        }
    }
    p, em {
        font-weight: lighter;
        font-size: 16px;
        @media (max-width: $mq-mobile) {
            text-shadow: 0px 0px 11px rgb(0, 0, 0);
            font-size: 14px;
        }
    }

    .swiper-slide-title_nav {
        color: #CDCDCD;
        font-weight: 700;
        font-size: 14px;
        span {
            &:nth-child(1) {
                color: $white;
                font-size: 28px;
            }
            &:nth-child(2) {
                margin-left: 60px;
                color: $white;
            }
        }
    }
}
.swiper-container {
    width: 100%;
    height: 100%;
    z-index: 0 !important;
  }

.swiper-container_gateau {
    width: 100%;
    height: 100%;
    z-index: 0 !important;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    img {
        width: 100%;
    }

    @media (max-width: $mq-mobile) {
        overflow: hidden;

        img {
            height: 100%;
            width: auto;
        }
    }
  }

  .swiper-pagination {
    text-align: right !important;
    padding-bottom: 5%;
    width: 90% !important;
    z-index: 1 !important;
    }
  .swiper-pagination-bullet {
      border: solid 2px $white;
  }
  .swiper-pagination-bullet-active{
    --swiper-theme-color: #ffffff;
} 
