$white : #FFFFFF;
$black : #000000;
$grey : #EBE7E4;

$primaryColor : #D49E44;
$secondaryColor: #825F2B;

$primaryFont: 'Montserrat', sans-serif;
$secondaryFont: 'Playfair Display', serif;

$mq-mobile: 728px;
$mq-tablet: 1080px;
$mq-desktop: 1200px;