header {
    opacity: 0;
    display: flex;
    height: 100%;
    min-height: 100%;
    align-items: center;
    background: url(/images/header_visu.jpg) no-repeat right top;
    background-size: cover;
    padding-bottom: 100px;
    overflow: hidden;
    position: relative;
    @media (max-width: $mq-mobile) {
        background-position: 20%;
    }

    #replay svg, #muted svg, #unmuted svg {
        width: 40px;
        height: 40px;
        left: 0;
    }
    #replay, #muted, #unmuted {
        background: none;
        position: absolute;
        z-index: 3;
        cursor: pointer;
        right: 20%;
        fill: black;
        border-radius: 230px;
        left: 0;
        margin-top: 40px;
    }
    #unmuted{
        display:none;
    }
    #replay {
        left: 60px;
    }
    .title--logo {
        background: url("../images/logo.svg") no-repeat left;
        background-size: contain;
        display: inline-block;
        text-indent: -9999px;
        color: transparent;
        overflow: hidden;
        text-align: left;
        width: 140px;
        height: 128px;
        margin: 40px 0 20px;

        @media (max-width: $mq-mobile) {
            width: 103px;
            height: 98px;
            margin-bottom: 10px;
            margin-top: 100px;
        }
    }
    .button a {
        background-color: transparent;
        font-size: 1rem;
    }

    .header_content {
        width: 500px;
        line-height: 2;
        position: relative;
        z-index: 1;
        @media (max-width: $mq-mobile) { 
            width: 100%;
        }
        .button {
            margin: 50px 0 30px;
            @media (max-width: $mq-mobile) {
                margin: 30px 0 20px;
            }
        }
    }

    video {
        position: absolute;
        left: 50%;
        transform:translateX(-50%) translateY(-50%) ;
        top: 50%;
        height: 100%;
    }
}