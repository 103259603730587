.calendly-inline-widget {
    margin-top: 84px;
    height:750px;
    .FndkwMHHQnIZgbYyMDIi.fA9VpmNSzncHsGoZ5jeX {
        min-height: auto !important;
    }
    @media (max-width: $mq-tablet) {
        height: 1200px;
        iframe {
            height: 1200px;
        }
    }
    @media (max-width: $mq-mobile) {
        .FndkwMHHQnIZgbYyMDIi.fA9VpmNSzncHsGoZ5jeX {
            min-height: auto !important;
        }
        height: 1200px;
        iframe {
            height: 1200px;
        }
    }
}

