@mixin default-btn($size, $backgroundColor, $color) {
    color: $color;
    background-color: $backgroundColor;
    font-size: $size;
    line-height: 16px;
    padding: 12px 10px;
    display: block;
    font-weight: 700;
    min-width: 130px;
    text-align: center;
    border: 2px solid $color;
    position: relative;
    outline:none;
    cursor: pointer;
    max-width: 150px;
    &:hover {
        color: $backgroundColor;
        background-color: $color;
        border: 2px solid $backgroundColor;
    }
}