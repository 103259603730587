footer {
    background-color: $black;
    color: #797979;
    padding: 120px 60px;
    @media (max-width: $mq-tablet) {
        padding: 90px 0px;
    }
    h2 {
        color: $white;
        font-size: 48px;
        @media (max-width: $mq-mobile) { 
        margin: 40px 0;
        }
    }
    h4 {
        color: $white;
        margin-top: 100px;
        @media (max-width: $mq-mobile) { 
            margin-top: 50px;
        }
        text-transform: uppercase;
        font-size: 16px;
    }

    .block-general, .block-horaire {
        height: 210px;
        @media (max-width: $mq-mobile) { 
            height: auto;
        }
        p {
            margin: 0;
        }
        h4 {
            margin-bottom: 0;
        }

    }
    .block-social{
        a {
            margin-right: 20px;
        }
    }
    .footer_title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $mq-mobile) { 
            display: block;
            text-align: center;

        }

        span  {
            margin-left: 20%;
            @media (max-width: $mq-mobile) { 
                margin-left: 0;
                margin-top: 40px;

            }

            a {
                font-size: 15px;
                color: $white;
                background:url(/images/scroll_icon.svg) no-repeat left;
                background-size: contain;
                padding-left: 40px;
            }
        }
    }
    .footer-one {
        display: flex;
        justify-content: space-between;
        width: 80%;
        @media (max-width: $mq-tablet) {
            width: 100%;
        }
        @media (max-width: $mq-mobile) {
            display: block;
        }
        
        .block-horaire_column {
            display: flex;
            justify-content: space-between;
            @media (max-width: $mq-mobile) {
                justify-content: left;
            }
        }
    }
    p {
        font-weight: lighter;
    }

    ul  {
        &:first-child {
            margin-right: 50px;
        }
        font-size: 15px;
        margin: 0;
        padding: 0;
        font-weight: lighter;
        li {
            list-style: none;
        }
    }
}