body {
    height: 100vh;
    margin: 0;
    background: $white;
    font-size: 18px;
    // font-family: $primaryFont;

    main, .content-wrap {
        height: 100%;
        min-height: 100%;
    }

    .container {
        width: 80%;
        margin: auto;
        @media (max-width: $mq-tablet) {
            width: 90%;
        }

    }

    h1, h2, h3 {
        margin: 0;
    }

    h2 {
        font-size: 72px;
        font-weight: bold;
        font-family: $secondaryFont;
        @media (max-width: $mq-tablet) {
            font-size: 62px;
        }
        @media (max-width: $mq-mobile) {
            font-size: 42px;
        }
    }

    h3 {
        font-weight: 300;
        font-family: $secondaryFont;
        font-size: 24px;
    }


    .primary_font {
        font-family: $secondaryFont;   
    }
    p {
        line-height: 2;
        font-weight: 400;
        font-size: 1rem;
        font-family: $secondaryFont;
        @media (max-width: $mq-mobile) {
            font-size: 0.8rem;
        }
    }

    a {
        text-decoration: none;
    }

    .button a{
        @include default-btn(13px, $white, $black);
    }

}