.biographie_content {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    background-color: #FBFBFB;
    margin-top: -5%;
    @media (max-width: $mq-mobile) {
        display: block;
    }
}
.biographie-content_img {
    width: 46%;
    overflow: hidden;
    height: 840px;
    position: relative;
    img {
        min-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    @media (max-width: $mq-mobile) {
        width: 100%;
        height: 480px;
    }
}
.biographie-content_txt {
    width: 30%;
    padding: 40px 12%;
    @media (max-width: $mq-mobile) {
        width: 76%;
        padding: 40px 12%;
    }
    h2 {
        font-size: 48px;
        margin: 20px 0 70px;
    }
    span {
        font-weight: 700;
        background: url(/images/icon_number.svg) no-repeat left;
        background-size: contain;
        padding-left: 30px;
        color: #CDCDCD;
    }
}