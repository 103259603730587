.title {
    text-align: center;
    margin: 150px auto;
    position: relative;
    width: 70%;
    @media (max-width: $mq-tablet) {
        width: 80%; 
        margin: 60px auto; 
    }
    span {
        color: #D49E44;
        font-size: 20px;
    }
    h3 {
        margin-top: 10px;
        font-family: $secondaryFont;
        font-style: italic;
        font-size: 28px;
        span {
            font-size: 45px;
            font-family: $secondaryFont;
        }
        &:before {
            content: "";
            height: 2px;
            width: 120px;
            background-color: #D49E44;
            position: absolute;
            top: 50%;
            left: 40px;
            transform: translateY(-50%);
            @media (max-width: $mq-tablet) {
                width: 40px;
            }
            @media (max-width: $mq-mobile) {
                display: none;
            }
        }
        &:after {
            content: "";
            height: 2px;
            width: 120px;
            background-color: #D49E44;
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            @media (max-width: $mq-tablet) {
                width: 40px;
            }
            @media (max-width: $mq-mobile) {
                display: none;
            }
        }
        
    }
    &:before {
        content: "";
        height: 30px;
        width: 30px;
        background-color: #D49E44;
        position: absolute;
        top: 50%;
        left: 0;
        transform: rotate(-90deg) translateX(50%);
        background: url(/images/icon_number.svg) no-repeat left;
        @media (max-width: $mq-mobile) {
            top: 0;
        }
    }
    &:after {
        content: "";
        height: 30px;
        width: 30px;
        background-color: #D49E44;
        position: absolute;
        top: 50%;
        right: 0;
        transform: rotate(90deg) translateX(-50%);
        background: url(/images/icon_number.svg) no-repeat left;
        @media (max-width: $mq-mobile) {
            top: 0;
        }
    }
}
.gateau_content {
    display: flex;
    align-items: center;
    background-color: #FBFBFB;
    @media (max-width: $mq-mobile) {
        display: block;
    }
}
.gateau-content_img {
    width: 46%;
    overflow: hidden;
    height: 840px;
    position: relative;
    img {
        min-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    @media (max-width: $mq-mobile) {
        width: 100%;
        height: 480px;
    }
}
.gateau-content_txt {
    width: 30%;
    padding: 40px 12%;
    @media (max-width: $mq-mobile) {
        width: 76%;
        padding: 40px 12%;
    }
    h2 {
        font-size: 48px;
        margin: 20px 0 70px;
    }
    span {
        font-weight: 700;
        background: url(/images/icon_number.svg) no-repeat left;
        background-size: contain;
        padding-left: 30px;
        color: #CDCDCD;
    }
}